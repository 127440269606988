import Breadcrumbs from "../../../components/breadcrumbs";
import SEO from "../../../components/seo";
import "./styles.css";
import "../styles.css";
import { NavLink } from "react-router-dom";

export default function PrivacyPolicyPage() {
  return (
    <div className="privacy-policy-page">
      <SEO
        title="Privacy Policy"
        description="Read our privacy policy to know how we handle your data and how you can use our services."
      />
      <Breadcrumbs
        data={[
          {
            label: "Privacy Policy",
            to: window.location.pathname,
          },
        ]}
      />
      {/* <div className="information-image">
        <img src="/assets/images/policy-300x276.png" alt="Privacy Policy" />
      </div> */}
      <div className="informations-brief">
        <div className="informations-brief-header">
          <div className="informations-brief-title">
            BeauteByYou Privacy Policy
          </div>
          <div className="informations-brief-date">
            Last updated: August 2024
          </div>
        </div>
        <div className="informations-brief-content"></div>
      </div>
      <article className="information-article">
        <h1>Introduction</h1>
        <p>
          Welcome to BeauteByYou. We value your privacy and are committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, and safeguard your data when you visit our website.
        </p>
        <h1>Information We Collect</h1>
        <ul>
          <li>
            <span className="information-bold">Personal Information</span>: When
            you sign up for our newsletter, create an account, or contact us, we
            may collect personal information such as your name and email
            address.
          </li>
          <li>
            <span className="information-bold">Usage Data</span>: We collect
            information about how you interact with our website, including your
            IP address, browser type, pages visited, and the time and date of
            your visit.
          </li>
          <li>
            <span className="information-bold">Cookies</span>: We use cookies to
            enhance your browsing experience. Cookies are small data files
            stored on your device that help us understand your preferences and
            improve our services.
          </li>
        </ul>
        <h1>How We Use Your Information</h1>
        <ul>
          <li>
            <span className="information-bold">To Provide Services</span>: We
            use your information to deliver the services you request, such as
            sending newsletters or responding to inquiries.
          </li>
          <li>
            <span className="information-bold">To Improve Our Website</span>: We
            analyze usage data to improve the functionality and content of our
            website.
          </li>
          <li>
            <span className="information-bold">To Communicate with You</span>:
            We may use your contact information to send you updates, promotional
            materials, and other information related to our services.
          </li>
        </ul>
        <h1>Sharing Your Information</h1>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties, except as described below:
        </p>
        <ul>
          <li>
            <span className="information-bold">Service Providers</span>: We may
            share your information with trusted third-party service providers
            who assist us in operating our website and conducting our business.
          </li>
          <li>
            <span className="information-bold">Legal Requirements</span>: We may
            disclose your information if required by law or in response to valid
            requests by public authorities.
          </li>
        </ul>
        <h1>Data Security</h1>
        <p>
          We implement a variety of security measures to protect your personal
          information. However, no method of transmission over the internet or
          electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </p>
        <h1>Your Rights</h1>
        <p>You have the right to:</p>
        <ul>
          <li>Access and update your personal information.</li>
          <li>Request the deletion of your personal data. </li>
          <li>Opt-out of receiving marketing communications.</li>
        </ul>
        <h1>Changes to This Privacy Policy</h1>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and we encourage you to review it
          periodically.
        </p>
        <h1>Contact Us</h1>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please{" "}
          <NavLink
            to="/informations/contact-us"
            state={{
              from: window.location.pathname,
            }}
          >
            <span className="information-underline">contact us.</span>
          </NavLink>
        </p>
      </article>
    </div>
  );
}
