import { useEffect, useRef, useState } from "react";
import IBlogFilter from "../../../domain/entities/blog/IBlogFilter";
import IBlogMini from "../../../domain/entities/blog/IBlogMini";
import IBlogRequestFilter from "../../../domain/entities/blog/IBlogRequestFilter";
import "./styles.css";
import SkeletonLoading from "../../loading/skeleton-loading";
import BlogFilter from "../blog-filter";
// import DropdownMenu from "../../utilities/dropdown";
import BlogListItem from "../blog-list-item";
import FilterIcon from "../../../assets/icons/filter";
import CloseIcon2 from "../../../assets/icons/close-2";

interface IBlogListProps {
  filters?: IBlogFilter;
  blogs: IBlogMini[];
  isLoading: boolean;
  loadNextPage: () => void;
  setPage: (page: number) => void;
  allPagesLoaded: boolean;
  totalPages: number;
  currentPage: number;
  setCurrentFilters: React.Dispatch<React.SetStateAction<IBlogRequestFilter>>;
}

export default function BlogsList({
  filters,
  blogs,
  isLoading,
  loadNextPage,
  setPage,
  allPagesLoaded,
  setCurrentFilters,
  totalPages,
  currentPage,
}: IBlogListProps) {
  const [lastElement, setLastElement] = useState(null);
  const [mobileFilterVisible, setMobileFilterVisible] = useState(false);
  const [closingMobileFilter, setClosingMobileFilter] = useState(false);

  /**
   * Shows the page numbers to be displayed on the pagination buttons
   */
  function paginationPages(): number[] {
    if (totalPages === 1) return [];

    const pages: number[] = [];
    const start = currentPage - 2 > 0 ? currentPage - 2 : 1;
    const end = currentPage + 2 < totalPages ? currentPage + 2 : totalPages;

    if (start !== 1) pages.push(1);
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    if (end !== totalPages) pages.push(totalPages);

    if (pages.length <= 1) return [];
    return pages;
  }

  async function loadNextHandler() {
    if (!allPagesLoaded) loadNextPage();
  }

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        loadNextHandler();
      }
    })
  );

  useEffect(() => {
    if (allPagesLoaded) {
      if (lastElement) observer.current.unobserve(lastElement);
      observer.current.disconnect();
      return;
    }

    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement, allPagesLoaded]);

  function openMobileFilter() {
    setClosingMobileFilter(false);
    setMobileFilterVisible(true);
    document.body.style.overflow = "hidden";
  }

  function closeMobileFilter() {
    setClosingMobileFilter(true);
    setTimeout(() => {
      setMobileFilterVisible(false);
      setClosingMobileFilter(false);
      document.body.style.overflow = "auto";
    }, 250);
  }

  function displayFilters(): boolean {
    if (!filters) return false;
    if (filters.authors.length > 1) return true;
    if (filters.categories.length > 1) return true;
    if (filters.others.length > 0) return true;
    return false;
  }

  if (blogs.length === 0 && !isLoading)
    return <div className="blogs-list"></div>;

  return (
    <div className="blogs-list-container">
      {!filters && isLoading ? (
        <div className="blog-sort">
          <SkeletonLoading />
        </div>
      ) : (
        <div className="blog-sort">
          <div className="blog-sort-heading">Explore Blogs</div>
          {/* <div className="blog-sortby">
              Sort By{" "}
              <DropdownMenu
                id="sort-by-blog"
                title="Sort Blog By"
                options={[
                  { label: "Newest", value: "newest" },
                  { label: "Oldest", value: "oldest" },
                ]}
                onSelect={(option) => {
                  setCurrentFilters((oldCurrentFilters) => ({
                    ...oldCurrentFilters,
                    sort_by: option,
                  }));
                }}
              />
            </div> */}
          {displayFilters() && (
            <div className="blog-sortby mobile" onClick={openMobileFilter}>
              <FilterIcon />
            </div>
          )}
        </div>
      )}
      <div className="blog-details-pagination">
        {currentPage > 1 && (
          <div
            className="blog-details-pagination-item"
            onClick={() => {
              setPage(currentPage - 1);
            }}
          >
            &lt;
          </div>
        )}
        {paginationPages().map((page, index) => (
          <div
            key={`blg-pgntn-${page}-${index}`}
            className={`blog-details-pagination-item${
              currentPage === page ? " active" : ""
            }`}
            onClick={() => {
              setPage(page);
            }}
          >
            {page}
          </div>
        ))}
        {currentPage < totalPages && (
          <div
            className="blog-details-pagination-item"
            onClick={() => {
              setPage(currentPage + 1);
            }}
          >
            &gt;
          </div>
        )}
      </div>
      <div className="blogs-list">
        {filters && displayFilters() ? (
          <div className="blog-filters">
            <div
              className={`blog-filters-content${
                mobileFilterVisible ? " visible" : ""
              }${closingMobileFilter ? " closing" : ""}`}
            >
              <div className="blog-filter-heading">
                <div className="blog-filter-heading-text">
                  <FilterIcon /> Filters
                </div>
                <div className="close-drawer" onClick={closeMobileFilter}>
                  <CloseIcon2 />
                </div>
              </div>
              {filters.categories.length > 1 && (
                <BlogFilter
                  data={filters.categories.map((category) => ({
                    name: category,
                    slug: category,
                  }))}
                  name="Categories"
                  setFilterValue={(slugs) =>
                    setCurrentFilters((oldCurrentFilters) => ({
                      ...oldCurrentFilters,
                      tags: slugs.length === 0 ? undefined : slugs,
                    }))
                  }
                />
              )}
              {filters.authors.length > 1 && (
                <BlogFilter
                  data={filters.authors.map((author) => ({
                    name: author,
                    slug: author,
                  }))}
                  name="Authors"
                  setFilterValue={(slugs) =>
                    setCurrentFilters((oldCurrentFilters) => ({
                      ...oldCurrentFilters,
                      authors: slugs.length === 0 ? undefined : slugs,
                    }))
                  }
                />
              )}
              {filters.others.map(
                (otherFilter, index) =>
                  otherFilter.filters.length > 1 && (
                    <BlogFilter
                      key={`blog-filter-${index}-other`}
                      data={otherFilter.filters}
                      name={otherFilter.type}
                      setFilterValue={(slugs) =>
                        setCurrentFilters((oldCurrentFilters) => ({
                          ...oldCurrentFilters,
                          others: slugs.length === 0 ? undefined : slugs,
                        }))
                      }
                    />
                  )
              )}
              <div className="blog-filter-actions">
                <button
                  className="reverse"
                  type="button"
                  onClick={() => {
                    closeMobileFilter();
                    window.location.reload();
                  }}
                >
                  Clear All
                </button>
                <button type="button" onClick={closeMobileFilter}>
                  Apply Filters
                </button>
              </div>
            </div>
            <div className="grey-out" onClick={closeMobileFilter}></div>
          </div>
        ) : isLoading ? (
          <div className="blog-filters">
            {Array.from({ length: 2 }).map((_, index) => (
              <SkeletonLoading key={`skel-ld-bl-fil-${index}`} />
            ))}
          </div>
        ) : (
          <div className="blog-filters"></div>
        )}
        <div className="blog-details">
          <div className="blog-details-content">
            {blogs.map((blog, index) => (
              <BlogListItem
                data={blog}
                key={`blog-${index}-list`}
                ref={
                  index === blogs.length - 1 ? (setLastElement as any) : null
                }
              />
            ))}
            {isLoading &&
              Array.from({ length: 15 }).map((_, index) => (
                <SkeletonLoading key={`skel-ld-det-blog-${index}`} />
              ))}
          </div>
          {allPagesLoaded && (
            <div className="end-of-results">
              <span>♦ End of Results ♦</span>
            </div>
          )}
          {!(isLoading || allPagesLoaded) && (
            <div className="end-of-results">
              <button onClick={loadNextHandler}>
                <span>Load More</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
