import LandingDiscover from "../../components/landing/discover";
// import LandingEssentials from "../../components/landing/essentials";
import "./styles.css";
import LandingTrending from "../../components/landing/trending";
import LandingFeatured from "../../components/landing/featured";
import LandingAdditionalWidgets from "../../components/landing/additional-widgets";
import LandingCommunity from "../../components/landing/community";
import LandingRepository from "../../domain/repositories/landing-repository";
import LandingService from "../../domain/services/landing-service";
import SEO from "../../components/seo";
import { useEffect, useState } from "react";
import IApiStatus from "../../domain/entities/api/IApiStatus";
import ILandingDiscover from "../../domain/entities/landing/ILandingDiscover";
import ICategory from "../../domain/entities/category/ICategory";
import ErrorComponentTiny from "../../components/error/tiny";

const landingRepository = new LandingRepository();
const landingService = new LandingService(landingRepository);

export default function LandingPage() {
  const [discoverItems, setDiscoverItems] = useState<ILandingDiscover[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const [apiDetails, setApiDetails] = useState<IApiStatus>({
    status: false,
    message: "",
    loading: true,
  });

  useEffect(() => {
    async function fetchDiscoverItems() {
      const response = await landingService.getDiscoverItem();

      if (response.status && response.data) {
        setDiscoverItems(response.data.discover);
        setCategories(response.data.categories);
      }

      setApiDetails({
        status: response.status,
        message: response.message,
        loading: false,
      });
    }

    fetchDiscoverItems();
  }, []);

  return (
    <div className="landing-page">
      <SEO
        title="Home"
        description="BeauteByYou - Match Your Shade. We know it's so tempting in the middle of so many choices, but we are here to help you find the best match for your lifestyle."
      />
      {!apiDetails.loading && !apiDetails.status ? (
        <ErrorComponentTiny
          title="An Error Occurred"
          description="We were unable to load the contents! Kindly try again"
          errorCode={apiDetails.message}
        />
      ) : (
        <LandingDiscover
          discoverItems={discoverItems}
          loading={apiDetails.loading}
        />
      )}
      {/* <LandingEssentials landingService={landingService} /> */}
      {!apiDetails.loading && !apiDetails.status ? null : (
        <LandingTrending
          landingService={landingService}
          categories={categories}
        />
      )}
      <LandingFeatured landingService={landingService} />
      <LandingAdditionalWidgets landingService={landingService} />
      <LandingCommunity landingService={landingService} />
    </div>
  );
}
