import { Link, NavLink } from "react-router-dom";
import IShadeFinderProduct from "../../../domain/entities/product/IShadeFinderProduct";
import SkeletonLoading from "../../loading/skeleton-loading";
import StarRatings from "react-star-ratings";
import shortenQuantityToString from "../../../utilities/text/shorten-quantity-to-string";
import "./styles.css";

interface IShadeFinderProductsListProps {
  products: IShadeFinderProduct[];
  isLoading: boolean;
}

export default function ShadeFinderProductsList({
  products,
  isLoading,
}: IShadeFinderProductsListProps) {
  return (
    <div className="products-list">
      <div className="products-details">
        <div className="product-details-content">
          {products.map((product, index) => (
            <div
              className="products-list-item"
              key={`sk-ld-shd-mtchr-${index}`}
            >
              <div className="products-list-item-image">
                <img
                  src={encodeURI(product.images[0])}
                  alt="Product"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/assets/placeholders/product.svg";
                  }}
                />
              </div>

              <Link
                to={`/products/${product.slug}?variant=${product.variants[0]?.id}`}
                state={{
                  from: window.location.pathname,
                }}
              >
                <div className="selected-variant">
                  <div
                    className="variant-item"
                    style={{
                      backgroundColor: product.variants[0]?.value,
                    }}
                    title={product.variants[0]?.name}
                  ></div>
                  <div className="variant-description">
                    {product.variants[0]?.name}
                  </div>
                </div>
              </Link>
              <div className="similarity-score">
                Similarity Score:{" "}
                <strong>
                  {Math.round(
                    (product.variants[0]?.similarity_score || 0) * 100
                  ) / 100}
                  %
                </strong>
              </div>
              <div className="products-list-item-name">
                <NavLink
                  to={`/products/${product.slug}?variant=${product.variants[0]?.id}`}
                  state={{ from: window.location.pathname }}
                >
                  {product.name}
                </NavLink>
              </div>
              <div className="products-list-item-details">
                <div className="products-list-item-rating">
                  <StarRatings
                    rating={product.rating}
                    starRatedColor="var(--theme-color)"
                    numberOfStars={5}
                    starDimension="15px"
                    starSpacing="0px"
                  />
                  ({shortenQuantityToString(product.reviews)})
                </div>
                <div className="products-list-item-price">
                  From{" "}
                  <span className="theme-color">
                    {product.currency}
                    {product.price.toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="product-action-button">
                <NavLink
                  to={`/products/${product.slug}?variant=${product.variants[0]?.id}`}
                  state={{
                    from: window.location.pathname,
                  }}
                >
                  Find Out More
                </NavLink>
              </div>
            </div>
          ))}
          {isLoading &&
            Array.from({ length: 15 }).map((_, index) => (
              <SkeletonLoading key={`skel-ld-det-${index}`} />
            ))}
        </div>
      </div>
    </div>
  );
}
