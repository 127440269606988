import { useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs";
import SEO from "../../../components/seo";
import { useUserContext } from "../../../contexts/user";
import "./styles.css";
import { NavLink } from "react-router-dom";

export default function ContactUsPage() {
  const { isLoggedIn, user } = useUserContext();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  return (
    <div className="contact-us-page">
      <SEO
        title="Contact Us"
        description="Having some trouble? Contact BeauteByYou here. We are always here to help you."
      />
      <Breadcrumbs
        data={[{ label: "Contact BeauteByYou", to: window.location.pathname }]}
      />
      <div className="contact-us-content">
        <div className="contact-us-content-image">
          <img
            src="/assets/images/contact-us-sidedrop.jpg"
            alt="Want To Tell Us Something?"
          />
        </div>
        <div className="contact-us-content-action">
          <div className="contact-us-header">Want To Tell Us Something?</div>
          {!isLoggedIn && (
            <span className="contact-us-help-tooltip">
              <NavLink
                to="/account/login"
                state={{
                  from: window.location.pathname,
                }}
              >
                Login
              </NavLink>{" "}
              to your account for connecting your queries and feedbacks with
              your account.
            </span>
          )}
          <span className="contact-us-help-tooltip">
            We'd love to hear from you! Whether you have questions, feedback, or
            need assistance, our team is here to help. Reach out to us through
            the form below, and we'll get back to you as soon as possible.
          </span>
          <form
            className="contact-us-form"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <label htmlFor="contact-us-name">Your Name</label>
            <input
              id="contact-us-name"
              type="text"
              placeholder="Your Name"
              value={isLoggedIn && user ? user.full_name : name}
              onChange={(e) => {
                if (!isLoggedIn) setName(e.target.value);
              }}
              disabled={isLoggedIn}
            />
            <label htmlFor="contact-us-email">Your Email</label>
            <input
              id="contact-us-email"
              type="email"
              placeholder="Your Email Address"
              value={isLoggedIn && user ? user.email : email}
              onChange={(e) => {
                if (!isLoggedIn) setEmail(e.target.value);
              }}
              disabled={isLoggedIn}
            />
            <label htmlFor="contact-us-message">Message</label>
            <textarea
              id="contact-us-message"
              placeholder="Type the query or feedback you are having"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />

            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}
