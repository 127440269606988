import FooterFloatingButton from "./floating-button";
import FooterNavigation from "./navigation";
import FooterNewsletter from "./newsletter";
import FooterSocials from "./socials";
import package_ from "../../../package.json";
import "./styles.css";

export default function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <FooterNewsletter />
        <div className="footer-content-next">
          <FooterNavigation />
          <FooterSocials />
        </div>
      </div>
      <div className="copyright">
        <p>
          Version {package_.version} &copy; 2024. All rights reserved by
          BeauteByYou
        </p>
      </div>
      <FooterFloatingButton />
    </footer>
  );
}
