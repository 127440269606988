import axios from "axios";
import AuthService from "../services/auth-service";

const baseURL =
  process.env.REACT_APP_BACKEND_URL || "https://api.beautebyyou.com";

if (!process.env.REACT_APP_BACKEND_URL) {
  console.error("Backend URL is not configured, using default value");
}

const api = axios.create({
  baseURL,
  withCredentials: true,
});
const refreshApi = axios.create({
  baseURL,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const currentTime = Date.now();
  config.headers["x-client-time"] = currentTime;

  return config;
});
api.interceptors.response.use(
  (response) => {
    const responseTime = Date.now();
    const requestTime = Number(
      response.config.headers["x-client-time"] || responseTime
    );

    const timeDifference = responseTime - requestTime;
    if (response.data) response.data.requestDuration = timeDifference;
    else
      response.data = {
        requestDuration: timeDifference,
      };

    return response;
  },
  async (error) => {
    if (
      error?.response?.status === 401 ||
      error?.response?.statusCode === 401 ||
      error?.response?.detail === "Unauthorized" ||
      error?.response?.data?.detail === "Unauthorized" ||
      error?.response?.data?.status === 401 ||
      error?.response?.data?.statusCode === 401
    ) {
      try {
        const authService = new AuthService();
        const res = await authService.refreshToken();
        if (res.data) return api.request(error.config);
        else return Promise.reject(error);
      } catch (err) {
        return Promise.reject(error);
      }
    } else return Promise.reject(error);
  }
);

export { baseURL as baseApiUrl, refreshApi };
export default api;
