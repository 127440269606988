import { useParams } from "react-router-dom";
import "./styles.css";
import SEO from "../../../components/seo";
import Breadcrumbs from "../../../components/breadcrumbs";
import { useEffect, useState } from "react";
import IBlog from "../../../domain/entities/blog/IBlog";
import IApiStatus from "../../../domain/entities/api/IApiStatus";
import BlogRepository from "../../../domain/repositories/blog-repository";
import BlogService from "../../../domain/services/blog-service";
import ErrorComponent from "../../../components/error";
import Markdown from "react-markdown";
import SkeletonLoading from "../../../components/loading/skeleton-loading";
import { useUserContext } from "../../../contexts/user";
import Error404Page from "../../error/error404";
import YoutubeEmbed from "../../../components/products/product-social/youtube";
import parseYoutubeVideoIdFromUrl from "../../../utilities/url/parseYoutubeVideoIdFromUrl";

const blogRepository = new BlogRepository();
const blogService = new BlogService(blogRepository);

export default function BlogPage() {
  const { slug } = useParams();
  const [blog, setBlog] = useState<IBlog | undefined>(undefined);
  const [apiStatus, setApiStatus] = useState<IApiStatus>({
    loading: true,
    message: "",
    status: false,
  });
  const [notFound, setNotFound] = useState(false);
  const { isLoggedIn } = useUserContext();

  useEffect(() => {
    async function getBlogBySlug() {
      try {
        if (!slug) return;

        const blog_id = Number(slug);
        const response = await blogService.getBlogBySlug(blog_id);

        if (!response || !response.status || !response.data) {
          if (response?.data === false) setNotFound(true);
          setApiStatus({
            status: response.status || false,
            loading: false,
            message:
              response.message || "An Error Occurred While Fetching Blog",
          });
          return;
        }

        setBlog(response.data);
        setApiStatus({
          status: response.status,
          loading: false,
          message: response.message,
        });
      } catch (error) {
        setApiStatus({
          status: false,
          loading: false,
          message: "An Error Occurred While Fetching Blog",
        });
      }
    }

    getBlogBySlug();
  }, [slug]);

  if (notFound) return <Error404Page />;

  return (
    <div className="blog-page">
      <SEO
        title={blog ? `${blog.title} - ${blog.author_name}` : "Blog"}
        description="Discover the latest blog on our website. Find out what's new and trending"
      />
      <Breadcrumbs
        data={[
          {
            label: "Blog",
            to: "/blog",
          },
          {
            label: blog?.title || "",
            to: window.location.pathname,
          },
        ]}
      />
      {apiStatus.loading ? (
        <div className="blog-content">
          <div className="blog-content-info">
            <div className="blog-content-title">
              <SkeletonLoading />
            </div>
            <div className="blog-content-brief">
              <SkeletonLoading />
            </div>
          </div>
          <div className="blog-content-item">
            <div className="blog-content-data">
              <div className="blog-content-data-image">
                <SkeletonLoading />
              </div>
              <div className="blog-content-md">
                <SkeletonLoading />
              </div>
            </div>
            <div className="blog-content-author">
              <div className="blog-content-author-image">
                <SkeletonLoading />
              </div>
              <div className="blog-content-author-name">
                <SkeletonLoading />
              </div>
            </div>
          </div>
        </div>
      ) : !apiStatus.status ? (
        <ErrorComponent
          title="An Error Occurred"
          description="An error occurred while fetching the blog. Please try again later."
          errorCode={apiStatus.message}
        />
      ) : blog ? (
        <div className="blog-content">
          <div className="blog-content-info">
            <div className="blog-content-title">{blog.title}</div>
            <div className="blog-content-brief">
              Published by{" "}
              <span className="blog-content-brief-theme">
                {blog.author_name}
              </span>
              {blog.created_at
                ? `, ${new Date(blog.created_at).toDateString()}`
                : ""}
              {blog.updated_at
                ? ` (Last Edited: ${new Date(blog.updated_at).toDateString()})`
                : ""}{" "}
              |
              <span className="blog-content-brief-theme">
                {" "}
                {blog.likes || 0} Likes
              </span>
            </div>
          </div>
          <div className="blog-content-item">
            <div className="blog-content-data">
              {blog.image && (
                <div className="blog-content-data-image">
                  <img src={blog.image} alt={blog.title} />
                </div>
              )}
              <div className="blog-content-md">
                <Markdown
                  components={{
                    a: ({ node, ...props }) => {
                      if (props?.href?.includes("youtube.com"))
                        return (
                          <>
                            <br />
                            <YoutubeEmbed
                              videoId={parseYoutubeVideoIdFromUrl(props.href)}
                              width="100%"
                              height="400px"
                            />
                            <br />
                          </>
                        );

                      return (
                        <a
                          {...props}
                          target="_blank"
                          rel="noreferrer noopener"
                          title="Open link in a new tab"
                        />
                      );
                    },
                  }}
                >
                  {blog.md_content}
                </Markdown>
              </div>
            </div>
            <div className="blog-content-author">
              <div className="blog-content-author-image">
                <img src={blog.author_image} alt={blog.author_name} />
              </div>
              <div className="blog-content-author-name">{blog.author_name}</div>
              {isLoggedIn && false && (
                <button className="blog-content-author-button">Follow</button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ErrorComponent
          title="An Error Occurred"
          description="An error occurred while fetching the blog. Please try again later."
        />
      )}
    </div>
  );
}
