import IBrand from "./IBrand";

export enum BrandManager {
  BOUJEEHER = "BeauteByYou",
  BRAND = "Brand",
  BRAND_AMBASSADOR = "BrandAmbassador",
  NOT_MANAGED = "NotManaged",
}

export default interface IBrandFull extends IBrand {
  caption?: string;
  banner?: string;
  bannerLinkTo?: string;
  logoBackground?: string;
  pageManagedBy?: BrandManager;
  displayBrandName?: boolean;
}
