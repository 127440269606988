import { useEffect, useRef, useState } from "react";
import IProductFilter from "../../domain/entities/product/IProductFilter";
import IProductMini from "../../domain/entities/product/IProductMini";
import ProductFilter from "./product-filter";
import ProductPriceFilter from "./product-price-filter";
import ProductsListItem from "./products-list-item";
import "./styles.css";
import SkeletonLoading from "../loading/skeleton-loading";
import ISearchProductRequest, {
  ISearchProductSortBy,
} from "../../domain/entities/product/ISearchProductRequest";
import FilterIcon from "../../assets/icons/filter";
import CloseIcon2 from "../../assets/icons/close-2";
import SortIcon from "../../assets/icons/sort";
import ProductSort from "./product-sort";
import ErrorComponentTiny from "../error/tiny";

interface IProductsListProps {
  filters?: IProductFilter;
  products: IProductMini[];
  isLoading: boolean;
  loadNextPage: () => void;
  setPage: (page: number) => void;
  allPagesLoaded: boolean;
  totalPages: number;
  currentPage: number;
  setCurrentFilters: React.Dispatch<
    React.SetStateAction<ISearchProductRequest>
  >;
  heading: string;
}

export default function ProductsList({
  filters,
  products,
  isLoading,
  loadNextPage,
  setPage,
  allPagesLoaded,
  setCurrentFilters,
  heading,
  totalPages,
  currentPage,
}: IProductsListProps) {
  const [lastElement, setLastElement] = useState(null);
  const [mobileFilterVisible, setMobileFilterVisible] = useState(false);
  const [closingMobileFilter, setClosingMobileFilter] = useState(false);

  /**
   * Shows the page numbers to be displayed on the pagination buttons
   */
  function paginationPages(): number[] {
    if (totalPages === 1) return [];

    const pages: number[] = [];
    const start = currentPage - 2 > 0 ? currentPage - 2 : 1;
    const end = currentPage + 2 < totalPages ? currentPage + 2 : totalPages;

    if (start !== 1) pages.push(1);
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    if (end !== totalPages) pages.push(totalPages);

    if (pages.length <= 1) return [];
    return pages;
  }

  async function loadNextHandler() {
    if (!allPagesLoaded) loadNextPage();
  }

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        loadNextHandler();
      }
    })
  );

  useEffect(() => {
    if (allPagesLoaded) {
      if (lastElement) observer.current.unobserve(lastElement);
      observer.current.disconnect();
      return;
    }

    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement, allPagesLoaded]);

  function openMobileFilter() {
    setClosingMobileFilter(false);
    setMobileFilterVisible(true);
    document.body.style.overflow = "hidden";
  }

  function closeMobileFilter() {
    setClosingMobileFilter(true);
    setTimeout(() => {
      setMobileFilterVisible(false);
      setClosingMobileFilter(false);
      document.body.style.overflow = "auto";
    }, 250);
  }

  function displayFilters(): boolean {
    if (!filters) return false;
    if (filters.brands.length > 1) return true;
    if (filters.categories.length > 1) return true;
    if (filters.price.minPrice !== filters.price.maxPrice) return true;
    if (filters.others.length > 0) return true;
    return false;
  }

  return (
    <div className="products-list-container">
      {!filters && isLoading ? (
        <div className="products-sort">
          <SkeletonLoading />
        </div>
      ) : (
        <div className="products-sort">
          <div className="products-sort-heading">
            {displayFilters() && (
              <div
                className="products-sortby mobile"
                onClick={openMobileFilter}
              >
                <FilterIcon />
              </div>
            )}
            {heading}
          </div>
          <div className="product-details-pagination">
            {currentPage > 1 && (
              <div
                className="product-details-pagination-item"
                onClick={() => {
                  setPage(currentPage - 1);
                }}
              >
                &lt;
              </div>
            )}
            {paginationPages().map((page, index) => (
              <div
                key={`prd-pgntn-${page}-${index}`}
                className={`product-details-pagination-item${
                  currentPage === page ? " active" : ""
                }`}
                onClick={() => {
                  setPage(page);
                }}
              >
                {page}
              </div>
            ))}
            {currentPage < totalPages && (
              <div
                className="product-details-pagination-item"
                onClick={() => {
                  setPage(currentPage + 1);
                }}
              >
                &gt;
              </div>
            )}
          </div>
        </div>
      )}
      <div className="products-list">
        {filters && displayFilters() ? (
          <div className="products-filter">
            <div
              className={`products-filter-content${
                mobileFilterVisible ? " visible" : ""
              }${closingMobileFilter ? " closing" : ""}`}
            >
              <div className="products-filter-heading sort">
                <div className="products-filter-heading-text">
                  <SortIcon /> Sort By
                </div>
                <div className="close-drawer" onClick={closeMobileFilter}>
                  <CloseIcon2 />
                </div>
              </div>
              <div className="filter-sort">
                <ProductSort
                  options={[
                    { label: "Default", value: ISearchProductSortBy.NAME },
                    { label: "Ratings", value: ISearchProductSortBy.RATING },
                    {
                      label: "Popularity",
                      value: ISearchProductSortBy.REVIEWS,
                    },
                    {
                      label: "Price (Low to High)",
                      value: ISearchProductSortBy.PRICE_ASC,
                    },
                    {
                      label: "Price (High to Low)",
                      value: ISearchProductSortBy.PRICE_DESC,
                    },
                  ]}
                  onSelect={(option) => {
                    setCurrentFilters((oldFilters) => ({
                      ...oldFilters,
                      sort_by: option,
                    }));
                  }}
                />
              </div>
              <div className="products-filter-heading">
                <div className="products-filter-heading-text">
                  <FilterIcon /> Filters
                </div>
              </div>
              {filters.brands.length > 1 && (
                <ProductFilter
                  name="Brands"
                  data={filters.brands}
                  setFilterValue={(slugs) => {
                    setCurrentFilters((oldFilters) => ({
                      ...oldFilters,
                      brand_slugs: slugs.length === 0 ? undefined : slugs,
                    }));
                  }}
                />
              )}
              {filters.categories.length > 1 && (
                <ProductFilter
                  name="Categories"
                  data={filters.categories}
                  setFilterValue={(slugs) => {
                    setCurrentFilters((oldFilters) => ({
                      ...oldFilters,
                      category_slugs: slugs.length === 0 ? undefined : slugs,
                    }));
                  }}
                />
              )}
              <ProductPriceFilter
                data={filters.price}
                setPrice={(minPrice, maxPrice) => {
                  setCurrentFilters((oldFilters) => ({
                    ...oldFilters,
                    min_price: minPrice,
                    max_price: maxPrice,
                  }));
                }}
              />
              {filters.others.map((filter, index) => (
                <ProductFilter
                  key={`product-filter-${index}`}
                  name={filter.type}
                  data={filter.filters}
                  setFilterValue={() => {}}
                />
              ))}
              <div className="product-filter-actions">
                <button
                  type="button"
                  className="reverse"
                  onClick={() => {
                    closeMobileFilter();
                    window.location.reload();
                  }}
                >
                  Clear All
                </button>
                <button type="button" onClick={closeMobileFilter}>
                  Apply Filters
                </button>
              </div>
            </div>
            <div className="grey-out" onClick={closeMobileFilter}></div>
          </div>
        ) : isLoading ? (
          <div className="products-filter">
            {Array.from({ length: 3 }).map((_, index) => (
              <SkeletonLoading key={`skel-ld-fil-${index}`} />
            ))}
          </div>
        ) : (
          <div className="products-filter"></div>
        )}
        <div className="products-details">
          {products.length === 0 && !isLoading ? (
            <ErrorComponentTiny
              title="No results found"
              description="Try another combination for better results"
            />
          ) : (
            <div className="product-details-content">
              {products.map((product, index) => (
                <ProductsListItem
                  key={`product-${index}`}
                  data={product}
                  ref={
                    index === products.length - 1
                      ? (setLastElement as any)
                      : null
                  }
                />
              ))}
              {isLoading &&
                Array.from({ length: 15 }).map((_, index) => (
                  <SkeletonLoading key={`skel-ld-det-${index}`} />
                ))}
            </div>
          )}
          {allPagesLoaded && (
            <div className="end-of-results">
              <span>♦ End of Results ♦</span>
            </div>
          )}
          {!(isLoading || allPagesLoaded) && (
            <div className="end-of-results">
              <button onClick={loadNextHandler}>
                <span>Load More</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
