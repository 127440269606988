import api from "../api";
import IApiResponse from "../entities/api/IApiResponse";
import IProduct from "../entities/product/IProduct";
import IProductMini from "../entities/product/IProductMini";
import IProductReview from "../entities/product/IProductReview";
import IProductRepository from "../entities/repositories/IProductRepository";
import ISearchProductRequest, {
  ISearchProductPagination,
} from "../entities/product/ISearchProductRequest";
import apiErrorChecks from "../../utilities/api/api-error-checks";
import IProductReviewRequest from "../entities/product/IProductReviewRequest";
import IShadeFinderProduct from "../entities/product/IShadeFinderProduct";
import IProductReviewEditRequest from "../entities/product/IProductReviewEditRequest";

export default class ProductRepository implements IProductRepository {
  async getProductBasedOnSlug(
    slug: string
  ): Promise<IApiResponse<IProduct | false>> {
    try {
      const response = await api.get<IProduct>("/api/v1/product", {
        params: { slug },
      });

      if (!response || !response.data) {
        return {
          status: false,
          message: "Failed to fetch the product",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        data: {
          ...response.data,
          images: response.data.images || [],
          variants:
            response.data.variants?.filter((variant) => variant.value) || [],
        },
        message: "Successfully fetched product",
        requestDuration: 0,
      };
    } catch (error: any) {
      if (error?.response?.status === 404)
        return {
          status: false,
          data: false,
          message: "Product Doesn't Exist",
          requestDuration: 0,
        };

      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async getProductReviews(
    productSlug: string
  ): Promise<IApiResponse<IProductReview[]>> {
    try {
      const response = await api.get<{ product_reviews: IProductReview[] }>(
        "/api/v1/product/review",
        {
          params: {
            slug: productSlug,
          },
        }
      );

      if (!response || !response.data) {
        return {
          status: false,
          message: "Failed to fetch product reviews",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        data: response.data.product_reviews,
        message: "Successfully fetched product reviews",
        requestDuration: 504,
      };
    } catch (error: any) {
      if (error?.response?.status === 404)
        return {
          status: true,
          data: [],
          message: "No Reviews Yet",
          requestDuration: 0,
        };

      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async searchProducts(
    filter: ISearchProductRequest
  ): Promise<IApiResponse<IProductMini[]>> {
    try {
      const response = await api.post<{
        requestDuration: number;
        products: IProductMini[];
        search_parameters: ISearchProductRequest;
        pagination: ISearchProductPagination;
      }>("/api/v1/search", filter);

      if (!response || !response.data) {
        return {
          status: false,
          message: "Failed to fetch products from the search query",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        data: response.data.products.map((product) => ({
          ...product,
          images: product.images || [],
          variants: product.variants?.filter((variant) => variant.value) || [],
        })),
        pagination: {
          page: response.data.pagination.current_page,
          totalPages: response.data.pagination.total_pages,
          total: response.data.pagination.records_matching_search,
          limit: response.data.pagination.page_length,
          lastPage: response.data.pagination.is_last_page,
        },
        message: "Successfully fetched products from the search query",
        requestDuration: response.data.requestDuration,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async searchSimilarProducts(
    variantId: number
  ): Promise<IApiResponse<IShadeFinderProduct[]>> {
    try {
      const response = await api.get<{
        requestDuration: number;
        products: IShadeFinderProduct[];
      }>("/api/v1/search/similar", {
        params: {
          variant_id: variantId,
        },
      });

      if (!response || !response.data) {
        return {
          status: false,
          message: "Failed to fetch products from the search query",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        data: response.data.products.map((product) => ({
          ...product,
          images: product.images || [],
        })),
        message: "Successfully fetched products from the search query",
        requestDuration: response.data.requestDuration,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async addProductReview(
    data: IProductReviewRequest
  ): Promise<IApiResponse<null>> {
    try {
      const response = await api.post<null>("/api/v1/product/review", data);

      if (!response) {
        return {
          status: false,
          message: "Failed to add product review",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        message: "Successfully added product review",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async editProductReview(
    data: IProductReviewEditRequest
  ): Promise<IApiResponse<null>> {
    try {
      const response = await api.put<null>("/api/v1/product/review", data);

      if (!response) {
        return {
          status: false,
          message: "Failed to edit product review",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        message: "Successfully edited product review",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async deleteProductReview(reviewId: number): Promise<IApiResponse<null>> {
    try {
      const response = await api.delete<null>("/api/v1/product/review", {
        data: {
          review_id: reviewId,
        },
      });

      if (!response) {
        return {
          status: false,
          message: "Failed to delete product review",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        message: "Successfully deleted product review",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async likeProductReview(reviewId: number): Promise<IApiResponse<null>> {
    try {
      const response = await api.post<null>("/api/v1/product/review/like", {
        review_id: reviewId,
      });

      if (!response) {
        return {
          status: false,
          message: "Failed to like product review",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        message: "Successfully liked product review",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async dislikeProductReview(reviewId: number): Promise<IApiResponse<null>> {
    try {
      const response = await api.post<null>("/api/v1/product/review/dislike", {
        review_id: reviewId,
      });

      if (!response) {
        return {
          status: false,
          message: "Failed to dislike product review",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        message: "Successfully disliked product review",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async undoLikeDislikeProductReview(
    reviewId: number
  ): Promise<IApiResponse<null>> {
    try {
      const response = await api.delete<null>("/api/v1/product/review/undo", {
        data: {
          review_id: reviewId,
        },
      });

      if (!response) {
        return {
          status: false,
          message: "Failed to undo like/dislike product review",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        message: "Successfully undone like/dislike product review",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async addProductToWishlist(productSlug: string): Promise<IApiResponse<null>> {
    try {
      const response = await api.post<null>("/api/v1/product/wishlist", {
        product_slug: productSlug,
      });

      if (!response) {
        return {
          status: false,
          message: "Failed to add product to wishlist",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        message: "Successfully added product to wishlist",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }

  async removeProductFromWishlist(
    productSlug: string
  ): Promise<IApiResponse<null>> {
    try {
      const response = await api.delete<null>("/api/v1/product/wishlist", {
        data: {
          product_slug: productSlug,
        },
      });

      if (!response) {
        return {
          status: false,
          message: "Failed to remove product from wishlist",
          requestDuration: 0,
        };
      }

      return {
        status: true,
        message: "Successfully removed product from wishlist",
        requestDuration: 0,
      };
    } catch (error: any) {
      const apiErrors = apiErrorChecks(error);
      if (apiErrors.isApiError && apiErrors.message)
        return {
          status: false,
          message: apiErrors.message,
          requestDuration: 0,
        };

      return {
        status: false,
        message: `An unknown error occurred on our end. Check your network connection and try again. Detail: ${
          error?.response?.data?.message ||
          error?.response?.message ||
          error?.message
        }`,
        requestDuration: 0,
      };
    }
  }
}
