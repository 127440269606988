import { useState } from "react";
import { ISearchProductSortBy } from "../../../domain/entities/product/ISearchProductRequest";
import "./styles.css";
import ProductSortSelect from "./product-sort-select";

interface IProductSortProps {
  options: {
    label: string;
    value: ISearchProductSortBy;
  }[];
  onSelect: (value: ISearchProductSortBy) => void;
}

export default function ProductSort(data: IProductSortProps) {
  const [selected, setSelected] = useState<ISearchProductSortBy>(
    data.options[0].value
  );

  function selectionHandler(value: ISearchProductSortBy) {
    if (value === selected) {
      setSelected(ISearchProductSortBy.RATING);
      data.onSelect(ISearchProductSortBy.RATING);
    } else {
      setSelected(value);
      data.onSelect(value);
    }
  }

  return (
    <div className="product-sort">
      {data.options.map((option, index) => (
        <ProductSortSelect
          key={`product-sort-${index}`}
          label={option.label}
          value={option.value}
          selected={selected === option.value}
          onSelect={() => {
            selectionHandler(option.value);
          }}
        />
      ))}
    </div>
  );
}
